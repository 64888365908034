<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        :disabled="getProp('disabled', false)"
        :loading="loading"
        v-bind="{ ...attrs, ...$attrs }"
        :color="getProp('color', 'primary')"
        :class="buttonClasses"
        data-test-id="copy-to-clipboard-btn"
        :text="textOnlyButton"
        :small="isSmallButton"
        v-on="on"
        @click="copyTextToClipboard"
      >
        <v-icon x-small>{{ iconType }}</v-icon>
        <span v-if="buttonText">
          {{ buttonText }}
        </span>
      </v-btn>
      <textarea
        ref="copyToClipboard"
        readonly
        class="copy-to-clipboard"
      ></textarea>
    </template>
    <span> {{ description || $t("actions.copy_to_clipboard") }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "CopyToClipboardBtn",
  props: {
    text: {
      type: [Function, String, Number],
      required: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonClasses: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "far fa-copy",
    },
    textOnlyButton: {
      type: Boolean,
      default: false,
    },
    isSmallButton: {
      type: Boolean,
      default: true,
    },
    copySuccessText: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$addEventListener(
      "focusin",
      this.handleFocusin,
      this.$refs.copyToClipboard
    )
  },
  methods: {
    handleFocusin(e) {
      e.stopPropagation()
    },
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    copyTextToClipboard() {
      try {
        this.loading = true
        let text
        if (typeof this.text === "function") {
          text = this.text()
        } else {
          text = this.text
        }

        this.$refs.copyToClipboard.value = text
        this.$refs.copyToClipboard.focus()
        this.$refs.copyToClipboard.select()

        document.execCommand("copy")
        this.$emit("copied")
        this.$notifications.success(
          this.copySuccessText
            ? this.copySuccessText
            : this.$t("content.copy_success")
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.copy_error"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.copy-to-clipboard {
  position: absolute;
  left: -9999px;
}
</style>
